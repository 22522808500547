<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Create a Transport System</h4>
                        <form @submit.prevent="createBiker" ref="bikerForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Name</label>
                                        <input
                                                type="text"
                                                v-model="name"
                                                placeholder="Name"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Surname</label>
                                        <input
                                                type="text"
                                                v-model="surname"
                                                placeholder="Surname"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input
                                                type="email"
                                                v-model="email"
                                                placeholder="Email"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label> Number</label>
                                    <input
                                            type="number"
                                            v-model="number"
                                            placeholder="Number"
                                            class="form-control"
                                    />

                                </div>

                            </div><div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Coverage area</label>
                                        <input
                                                type="text"
                                                v-model="coverage_area"
                                                placeholder="Coverage area"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="lodader__content" v-if="loader">
                <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
            </div>            
        </div>
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_post} from "../../../helpers/helper";


    export default {
        page: {
            title: "Create a Transport System",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                name:"",
                surname:"",
                telephone: "",
                email:"",
                coverageArea:"",
                user:null,
                loader: false,
                title: "Create a Transport System",
                items: [
                    {
                        text: "View Transports Systems",
                        href: "/biker/bikers"
                    },
                    {
                        text: "Create a Transport System",
                        active: true
                    }
                ],
            };

        },
        validations: {

        },
        methods: {
            createBiker(){
                this.loader=true
                this.user = JSON.parse(localStorage.getItem('user'))
                this.submitted = true;
                this.$v.$touch();
                axios_post("/bikers",{
                    name:this.name,
                    surname:this.surname,
                    number:this.number,
                    email:this.email,
                    coverage_area:this.coverage_area,
                    country_id:this.user.country_id


                },(d)=>{
                    window.location.href = '/biker/bikers'
                    console.log(d);

                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                    this.loader=false
                })

            },
            resetForm(){
                this.$refs['bikerForm'].reset()
            },
            // makeToast(variant = null) {
            //     this.$bvToast.toast('Biker Created', {
            //         title: `Success`,
            //         variant: variant,
            //         solid: true
            //     })
            // }


        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>